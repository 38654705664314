import type { Component, ComponentProps, JSX, ValidComponent } from 'solid-js';
import { Dynamic, createComponent } from 'solid-js/web';
import { twMerge } from 'tailwind-merge';

type Tag = keyof JSX.IntrinsicElements;

interface TwcFn<T extends ValidComponent> {
  (strings: TemplateStringsArray, ...keys: string[]): Component<ComponentProps<T>>;
  <V extends CVAVariants>(config: CVAConfig<V>): Component<ComponentProps<T> & CVAProps<V>>;
}

type Twc = (<T extends ValidComponent>(tag: T) => TwcFn<T>) & { [K in Tag]: TwcFn<K> };

type Primitives = string | number | boolean | null | undefined;
type ClassValue = Primitives | Record<string, Primitives> | ClassValue[];
type CVAVariants = Record<string, Record<string, ClassValue>>;
type CVAProps<V> = { [K in keyof V]?: keyof V[K] };
type CVAConfig<V> = {
  base: ClassValue;
  variants?: V;
  defaults?: CVAProps<V>;
  computed?: (props: CVAProps<V>) => ClassValue;
};

export type VariantProps<F extends (props: CVAProps<CVAVariants>) => string> = F extends (props: infer P) => string ? P : never;

export const cn = (...inputs: ClassValue[]): string => {
  const tokens: string[] = [];

  inputs.forEach((item) => {
    if (!item) return;

    if (typeof item === 'string') {
      return tokens.push(item);
    }

    if (typeof item === 'object') {
      if (Array.isArray(item)) {
        return tokens.push(cn(...item));
      }

      for (const key in item) {
        item[key] && tokens.push(key);
      }
    }
  });

  return twMerge(...tokens);
};

export const cva =
  <V extends CVAVariants>(config: CVAConfig<V>) =>
  (props: CVAProps<V>, ...className: ClassValue[]) => {
    const { base, variants, computed, defaults } = config;

    for (const key in defaults) {
      if (props[key] == null) {
        props[key] = defaults[key];
      }
    }

    const values: ClassValue = [base];

    if (variants != null) {
      for (const key in variants) {
        const variant = variants[key];
        const value = props[key]!;
        values.push(variant[value]);
      }
    }

    computed != null && values.push(computed(props));

    return cn(...values, ...className);
  };

const isTemplateStringsArray = (value: any): value is TemplateStringsArray => value?.raw != null;

const wrap = <T extends ValidComponent>(tag: T): TwcFn<T> => {
  return (config: any, ...rest: any[]) => {
    const css = isTemplateStringsArray(config) ? config.reduce((acc, string, i) => acc + rest[i - 1] + string) : cva(config);
    return (props: any) => {
      const className = typeof css === 'function' ? css(props) : css;
      return createComponent(Dynamic, { ...props, component: tag, class: cn(className, 'class' in props && props.class) });
    };
  };
};

export const twc = new Proxy(wrap, { get: (_, tag: Tag) => wrap(tag) }) as Twc;
